<template>
  <v-main fill-height class="home home_container">
    <!-- button for back -->
    <v-btn
      class="home_back_button"
      icon
      @click="goBack()"
      x-large
      color="pink"
      outlined
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-container fill-height class="home_content">
      <!-- message post -->
      <v-row>
        <v-col v-bind:key="id" v-for="(message, id) in Messages">
          <v-card class="mx-auto card-padding" max-width="600">
            <v-img
              class="white--text align-end"
              height="400px"
              :src="message.image"
            />
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">{{ message.title }}</h3>
                <div>{{ message.text }}</div>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- For background effect -->
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </v-main>
</template>

<script>
import message1 from "@/assets/angel/2022-12-22 03.10.27.jpg";
import message2 from "@/assets/angel/2022-12-22 03.11.01.jpg";
import message3 from "@/assets/angel/2022-12-22 03.11.16.jpg";
import message4 from "@/assets/angel/2022-12-22 03.11.22.jpg";
import message5 from "@/assets/angel/2022-12-22 03.13.54.jpg";
import message6 from "@/assets/angel/2022-12-22 03.14.05.jpg";
import message7 from "@/assets/angel/2022-12-22 03.14.20.jpg";
import message8 from "@/assets/angel/2022-12-22 03.14.52.jpg";
import message9 from "@/assets/angel/2022-12-22 03.15.27.jpg";
import message10 from "@/assets/angel/2022-12-22 03.19.02.jpg";
import message11 from "@/assets/angel/2022-12-22 03.44.55.jpg";
import message12 from "@/assets/angel/2022-12-22 03.47.39.jpg";
import message13 from "@/assets/angel/2022-12-22 03.49.21.jpg";
import message14 from "@/assets/angel/20210521_180239.jpg";
import message15 from "@/assets/angel/20210602_213851.jpg";
import message19 from "@/assets/angel/photo_2022-12-22 04.08.29.jpeg";
import message21 from "@/assets/angel/photo_2022-12-22 04.08.35.jpeg";
import message22 from "@/assets/angel/photo_2022-12-22 04.08.41.jpeg";
import message23 from "@/assets/angel/photo_2022-12-22 04.08.44.jpeg";
import message24 from "@/assets/angel/photo_2022-12-22 04.08.47.jpeg";
import message25 from "@/assets/angel/photo_2022-12-22 04.08.50.jpeg";
import message27 from "@/assets/angel/photo_2022-12-22 04.08.56.jpeg";
import message28 from "@/assets/angel/photo_2022-12-22 04.09.02.jpeg";
import message29 from "@/assets/angel/photo_2022-12-22 04.09.18.jpeg";
import message30 from "@/assets/angel/photo_2022-12-22 04.09.22.jpeg";

export default {
  name: "AngelMessages",
  data() {
    return {
      Messages: [
        {
          title: "Наше время провождение на парах",
          text: "Мы дурачились как дети, но ведь это было наше время, те моменты, которые мы никогда не забудем. Наши студенческие годы",
          image: message1,
        },
        {
          title: "Наша первая прогулка на природе",
          text: "Лесопарк, осень, лучшая пора для приключений, в тот день мы прогуляли 2 пары и пошли гулять)",
          image: message2,
        },
        {
          title: "Be happy!!! Life is good",
          text: "Твой первый рисунок в моей тетради, тогда я был грустный, но ты подняла мне настроение этим рисунком, спасибо тебе за это",
          image: message3,
        },
        {
          title: "Осминожек с шариком!)",
          text: "Именно этот рисунок мне понравился больше чем все остальные, ты так красиво нарисовала осминожку, поэтому я и решил его тут оставить, это твой рисунок от чистого сердца!",
          image: message4,
        },
        {
          title: "Счастье в твоих глазах",
          text: "Мне очень понравилась эта фотография поэтому и решил оставить), ты тут очень милая, а твои глаза так и говорят о счастье",
          image: message5,
        },
        {
          title: "Наш первый поход в кофейню",
          text: "Это первый наш поход в арому и первый кофе который тебе понравился)",
          image: message6,
        },
        {
          title: "Значок говорящий о многом",
          text: "Ты увлеклась тогда очень NASA и я решил тебе подарить этот значок, ты была очень рада этому подарку",
          image: message7,
        },
        {
          title: "Студенческий",
          text: "Мы наконец-то официально стали студентами в тот момент!)",
          image: message8,
        },
        {
          title: "Первая работа сделанная тобой и поход в 7 склад",
          text: "В тот день ты впервые сделала сама работу по питону, я был очень счастлив что смог тебя обучить ему, ты была очень большой умничкой!)",
          image: message9,
        },
        {
          title: "Билеты счастья",
          text: "Наша первая поездка на колесе обозрения, шикарный вид, ты, я, наблюдаем за красивейшим городом, это было просто сказочно! Эта фотография вызывает у меня счастливые воспоминания",
          image: message10,
        },
        {
          title: "Твой первый кофе из старбакс",
          text: "Тебе очень понравилось это кофе, но кроме него это была отличная прогулка по городу)",
          image: message11,
        },
        {
          title: "Мороженное с скрытым смыслом",
          text: "Я не нашел фотографию за тот период, но есть много видео и мы оба помним этот день, два счастливых ребенка проводят время вместе,на детской площадке))",
          image: message12,
        },
        {
          title: "Наша первая поездка на дачу и знакомство с твоими родителями",
          text: "Это был шикарный день с кучей воспоминаний, он не нуждается в описании, ибо мы оба помним этот день)",
          image: message13,
        },
        {
          title: "Открытие Никольского",
          text: "На день открытия Никольского мы не упустили момента полакомиться бесплатным попкорном!)",
          image: message14,
        },
        {
          title: "Один из лучших закатов",
          text: "В этот день мы решили проехаться на фуникулере, ты и сама прекрасно помнишь как мы прекрасно провели время, это было просто сказочно",
          image: message15,
        },
        {
          title: "Отличная прогулка и вкусный обед в львовских курасах",
          text: "",
          image: message19,
        },
        {
          title: "Ты залипаешь в телефон",
          text: "Но мы знаем что было после)",
          image: message21,
        },
        {
          title: "Уснула",
          text: "А это ты уснула в автобусе, ты так много тратила энергии за день, что иногда засыпала у меня на плече в маршрутке",
          image: message22,
        },
        {
          title: "Хеллоуин",
          text: "Мы дурачимся перед хелллоуином в магазине",
          image: message23,
        },
        {
          title: "Парк шевченко",
          text: "Сидели на лавочке и дурачились фотками)",
          image: message24,
        },
        {
          title: "Венок из листьев",
          text: "Ты сплела этот венок для меня, но все же фоткалась и носила ты его)",
          image: message25,
        },
        {
          title: "Первый поход на французский бульвар",
          text: "Но самое интересное было в магазине и после этого фото)",
          image: message27,
        },
        {
          title: "Ты в этом году счастливая",
          text: "За этот год было много трудностей, но мы их преодолели, это первое фото мне с начало войны",
          image: message28,
        },
        {
          title: "Фотосессия",
          text: "Лучшая наша с тобой общая фотография, мышонок)",
          image: message29,
        },
        {
          title: "Ты в поле",
          text: "Просто красивая фотография присланная тобой, мне",
          image: message30,
        },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.push("/angel/home");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/Home.scss";

.home {
  position: relative !important;
  overflow: hidden !important;
}

.home_container {
  height: 100% !important;
  background: #fde2e2 !important;
  position: relative !important;
}

.home_content {
  z-index: 1 !important;
  display: block !important;
  position: relative !important;
}

.home_back_button {
  z-index: 1000 !important;
}

.card-padding {
  padding-bottom: 2% !important;
}
</style>
