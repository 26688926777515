<template>
  <v-navigation-drawer
    rounded
    permanent
    :mini-variant.sync="mini"
    expand-on-hover
    app
    color="orange"
  >
    <!--002a4a-->
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img
            src="https://www.business2community.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640.png"
          ></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6" v-if="accountDetails">
            {{ accountDetails.username }}
          </v-list-item-title>
          <v-list-item-title class="text-h6" v-else>
            username
          </v-list-item-title>
          <v-list-item-subtitle v-if="accountDetails">
            {{ accountDetails.email }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>example@gmail.com</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item
        link
        v-for="(item, index) in navdItems"
        :key="index"
        :to="item.to"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavDrawer",
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "accountDetails"]),
  },
  data() {
    return {
      mini: true,
      navdItems: [
        {
          text: "Home",
          icon: "mdi-home",
          to: "/",
        },
        {
          text: "Settings",
          icon: "mdi-cog-outline",
          to: null,
        },
        {
          text: "Logout",
          icon: "mdi-logout",
          to: "/logout",
        },
      ],
    };
  },
};
</script>
