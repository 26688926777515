import session from "./session";

export default {
  getUrlByName(name) {
    return session.get(`/api/urls/get_by_name/`, { params: { name: name } });
  },
  getAllUrls() {
    return session.get(`/api/urls/get_all/`);
  },
};
