import base from "../api/base";
import {
  GET_URLS,
  GET_URLS_SUCCESS,
  GET_URLS_FAILURE,
  GET_URL,
  GET_URL_SUCCESS,
  GET_URL_FAILURE,
} from "./types";

const initialState = {
  urls: [],
  url: null,
};

const getters = {
  urls: (state) => state.urls,
  url: (state) => state.url,
};

const actions = {
  getUrlByName({ commit }, { name }) {
    commit(GET_URL);
    console.log("name", name);
    return base
      .getUrlByName(name)
      .then((response) => {
        commit(GET_URL_SUCCESS, response.data);
      })
      .catch(() => commit(GET_URL_FAILURE));
  },
  getAllUrls({ commit }) {
    commit(GET_URLS);
    return base
      .getAllUrls()
      .then((response) => {
        commit(GET_URLS_SUCCESS, response.data);
      })
      .catch(() => commit(GET_URLS_FAILURE));
  },
};

const mutations = {
  [GET_URLS](state) {
    state.urls = [];
  },
  [GET_URLS_SUCCESS](state, payload) {
    state.urls = payload;
  },
  [GET_URLS_FAILURE](state) {
    state.urls = [];
  },
  [GET_URL](state) {
    state.url = [];
  },
  [GET_URL_SUCCESS](state, payload) {
    state.url = payload;
  },
  [GET_URL_FAILURE](state) {
    state.url = [];
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
