<template>
  <v-main>
    <v-row v-for="(alert, index) in alerts" :key="index">
      <v-col>
        <v-alert
          dense
          outlined
          dismissible
          :type="alert.type"
          class="text-center"
          max-width="500px"
          style="margin-left: 35%"
        >
          {{ alert.message }}
        </v-alert>
      </v-col>
    </v-row>
    <v-container max-width="600px" min-width="360px" fill-height text-xs-center>
      <v-flex xs6 style="margin-left: 25%">
        <v-tabs
          v-model="tab"
          show-arrows
          background-color="orange accent-4"
          icons-and-text
          dark
          grow
        >
          <v-tabs-slider color="white darken-4"></v-tabs-slider>
          <v-tab v-for="(el, index) in tabs" :key="index">
            <v-icon large>{{ el.icon }}</v-icon>
            <div class="caption py-1">{{ el.name }}</div>
          </v-tab>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="loginForm" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="login.username"
                        :rules="loginRules"
                        label="Login"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="login.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Password"
                        hint="At least 4 characters"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                      <v-btn
                        x-large
                        block
                        :disabled="!valid"
                        color="success"
                        @click="authenticate(login.username, login.password)"
                      >
                        Login
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="registerForm" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="register.username"
                        :rules="[rules.required]"
                        label="Username"
                        maxlength="20"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="register.email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="register.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Password"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        block
                        v-model="register.verify"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, passwordMatch]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Confirm Password"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                      <v-btn
                        x-large
                        block
                        :disabled="!valid"
                        color="success"
                        @click="
                          signup(
                            register.username,
                            register.email,
                            register.password,
                            register.verify
                          )
                        "
                        >Register</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
    tab() {
      return this.$route.meta.id;
    },
    ...mapState("signup", [
      "registrationCompleted",
      "registrationError",
      "registrationLoading",
    ]),
    ...mapGetters(["alerts"]),
  },
  methods: {
    ...mapActions("signup", ["createAccount", "clearRegistrationStatus"]),
    authenticate(username, password) {
      if (this.$refs.loginForm.validate()) {
        this.$store.dispatch("auth/login", { username, password }).then(() => {
          if (this.$store.getters["auth/isAuthenticated"]) {
            this.$store.dispatch("auth/getAccountDetails", {
              username,
              password,
            });
            this.$router.push("/");
          }
        });
      }
    },
    signup(username, email, password1, password2) {
      if (this.$refs.registerForm.validate()) {
        this.createAccount({ username, password1, password2, email });
      }
    },
  },
  data: () => ({
    tabs: [
      { name: "Login", icon: "mdi-account" },
      { name: "Register", icon: "mdi-account-outline" },
    ],
    valid: true,
    register: {
      username: "",
      email: "",
      password: "",
      verify: "",
    },
    login: {
      username: "",
      password: "",
    },
    loginRules: [(v) => !!v || "Required"],
    emailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    show1: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => (v && v.length >= 8) || "Min 8 characters",
    },
  }),
  beforeRouteLeave(to, from, next) {
    next();
  },
};
</script>
