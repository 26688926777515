<template>
  <v-main style="background: #171515">
    <v-container fluid class="py-16 pl-16 pr-0">
      <v-card style="padding-bottom: 45%" fixed class="rounded-xl">
        <v-container fluid class="justify-center">
          <span
            :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
            class="font-weight-black"
          >
            Development in progress
          </span>
        </v-container>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "profile",
  computed: {
    ...mapGetters("auth", ["accountDetails"]),
  },
};
</script>
