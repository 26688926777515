import Vue from "vue";
import Router from "vue-router";

import Home from "../views/Home";
import Login from "../views/Login";
import Lost from "../views/Lost";
// import PasswordReset from "../views/PasswordReset";
// import PasswordResetConfirm from "../views/PasswordResetConfirm";
import VerifyEmail from "../views/VerifyEmail";
import Profile from "../views/Profile";
import Admin from "../views/Admin";

import store from "../store";
import AngelLanding from "@/views/AngelLanding";
import AngelHome from "@/views/AngelHome";
import AngelMessages from "@/views/AngelMessages";
import AngelVideo from "@/views/AngelVideo";

const requireAuthenticated = (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next("/login");
  } else {
    next();
  }
};

const requireUnauthenticated = (to, from, next) => {
  store.dispatch("auth/initialize").then(() => {
    if (store.getters["auth/isAuthenticated"]) {
      next("/");
    } else {
      next();
    }
  });
};

const requireAdmin = (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next("/login");
  }
  if (!store.getters["auth/accountDetails"].is_staff) {
    next("/login");
  } else {
    next();
  }
};

const redirectLogout = (to, from, next) => {
  store.dispatch("auth/logout").then(() => next("/login"));
};

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Home,
    },
    //  TODO: password reset
    // {
    //   path: "/password_reset",
    //   component: PasswordReset,
    // },
    // {
    //   path: "/password_reset/:uid/:token",
    //   component: PasswordResetConfirm,
    // },
    {
      path: "/signup",
      component: Login,
      meta: { id: 1 },
    },
    //  TODO: verify email
    {
      path: "/signup/:key",
      component: VerifyEmail,
    },
    {
      path: "/login",
      component: Login,
      beforeEnter: requireUnauthenticated,
      meta: { id: 0 },
    },
    {
      path: "/logout",
      beforeEnter: redirectLogout,
    },
    {
      path: "/profile",
      component: Profile,
      beforeEnter: requireAuthenticated,
    },
    {
      path: "/admin-panel",
      component: Admin,
      beforeEnter: requireAdmin,
    },
    {
      path: "/angel",
      component: AngelLanding,
    },
    {
      path: "/angel/home",
      component: AngelHome,
    },
    {
      path: "/angel/messages",
      component: AngelMessages,
    },
    {
      path: "/angel/video",
      component: AngelVideo,
    },
    {
      path: "*",
      component: Lost,
    },
  ],
});
