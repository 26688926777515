<template>
  <v-main fill-height class="home home_container">
    <!-- button for back -->
    <v-btn
      class="home_back_button"
      icon
      @click="goBack()"
      x-large
      color="pink"
      outlined
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-container fill-height class="home_content justify-center align-center">
      <!-- video -->
      <youtube video-id="-tSmKKS6Iec" :fit-parent="true" />
    </v-container>

    <!-- For background effect -->
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </v-main>
</template>

<script>
import Vue from "vue";
import VueYoutube from "vue-youtube";

Vue.use(VueYoutube);

export default {
  name: "AngelVideo",
  methods: {
    goBack() {
      this.$router.push("/angel/home");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/Home.scss";

.home {
  position: relative !important;
  overflow: hidden !important;
}

.home_container {
  height: 100vh !important;
  background: #fde2e2 !important;
  position: relative !important;
}

.home_content {
  z-index: 1 !important;
  display: block !important;
  position: relative !important;
}

.home_back_button {
  z-index: 1000 !important;
}
</style>
