<template>
  <v-main>
    <section id="hero">
      <v-row no-gutters>
        <video-background
          :src="require('@/assets/background_app.mp4')"
          style="height: 100vh"
        >
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
                    ]"
                    class="font-weight-light"
                  >
                    WELCOME TO
                  </span>

                  <br />

                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
                    ]"
                    class="font-weight-black"
                  >
                    bounce-back
                  </span>
                </v-col>
                <v-btn
                  class="align-self-end"
                  fab
                  outlined
                  @click="$vuetify.goTo('#about-project')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </video-background>
      </v-row>
    </section>

    <section id="about-project">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">ABOUT PROJECT</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          Bounce-back contains various projects from the developers of this
          project. All developers develop it on a voluntary basis using
          different tools for backend development based on django, frontend can
          vary from Vue.js to Jquery, each developer chooses what he likes, for
          more details about all projects developers you can find in
        </v-responsive>

        <v-avatar class="elevation-12 mb-12" size="128">
          <v-img src="@/assets/logo.svg"></v-img>
        </v-avatar>

        <div></div>
        <v-btn
          color="grey"
          class="outlined large"
          outlined
          @click="$vuetify.goTo('#team')"
        >
          <span class="grey--text text--darken-1 font-weight-bold"> Team </span>
        </v-btn>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="team" class="grey darken-3">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">Team</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>
        <cards-flipping></cards-flipping>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="stats">
      <v-parallax
        :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
        src="https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
      >
        <v-container fill-height>
          <v-row class="mx-auto">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="3"
            >
              <div class="text-center">
                <div
                  class="display-3 font-weight-black mb-4"
                  v-text="value"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>

    <section id="blog">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          Team best projects
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ src, text, title }, i) in articles"
            :key="i"
            cols="12"
            md="4"
          >
            <v-img
              :src="src"
              class="mb-4"
              height="275"
              max-width="100%"
            ></v-img>

            <h3
              class="font-weight-black mb-4 text-uppercase"
              v-text="title"
            ></h3>

            <div class="title font-weight-light mb-5" v-text="text"></div>

            <v-btn class="ml-n4 font-weight-black" text> Visit Project </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <!--    <v-sheet id="contact" color="#333333" dark tag="section" tile>-->
    <!--      <div class="py-12"></div>-->

    <!--      <v-container>-->
    <!--        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">-->
    <!--          Contact Us-->
    <!--        </h2>-->

    <!--        <v-responsive class="mx-auto mb-12" width="56">-->
    <!--          <v-divider class="mb-1"></v-divider>-->

    <!--          <v-divider></v-divider>-->
    <!--        </v-responsive>-->

    <!--        <v-theme-provider light>-->
    <!--          <v-row>-->
    <!--            <v-col cols="12">-->
    <!--              <v-text-field flat label="Name*" solo></v-text-field>-->
    <!--            </v-col>-->

    <!--            <v-col cols="12">-->
    <!--              <v-text-field flat label="Email*" solo></v-text-field>-->
    <!--            </v-col>-->

    <!--            <v-col cols="12">-->
    <!--              <v-text-field flat label="Subject*" solo></v-text-field>-->
    <!--            </v-col>-->

    <!--            <v-col cols="12">-->
    <!--              <v-textarea flat label="Message*" solo></v-textarea>-->
    <!--            </v-col>-->

    <!--            <v-col class="mx-auto" cols="auto">-->
    <!--              <v-btn color="accent" x-large> Submit </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-theme-provider>-->
    <!--      </v-container>-->

    <!--      <div class="py-12"></div>-->
    <!--    </v-sheet>-->
    <v-footer class="justify-center" color="#292929" height="100">
      <div
        class="title font-weight-light grey--text text--lighten-1 text-center"
      >
        &copy; {{ new Date().getFullYear() }} — Bounce-back, LLC — Made with 💜
        by Smerch
      </div>
    </v-footer>
  </v-main>
</template>

<script>
import CardsFlipping from "@/components/CardsFlipping.vue";
import Vue from "vue";
import VideoBackground from "vue-responsive-video-background-player";
import { mapGetters } from "vuex";

Vue.component("video-background", VideoBackground);

export default {
  name: "Home",
  components: {
    CardsFlipping,
  },
  data: () => ({
    articles: [
      {
        src: "https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        title: "Hell Heart",
        text: "Hell Heart is a telegram game in which the player has to build their castle, fight with other players, engage in politics, as well as to establish a kingdom (similar to the clan)",
      },
      {
        src: "https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        title: "Alexis Portfolio",
        text: "Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.",
      },
      {
        src: "https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80",
        title: "Small changes, big difference",
        text: "Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.",
      },
    ],
    stats: [
      ["∞", "Github Stars"],
      ["∞", "Releases"],
      ["∞", "Views/mo"],
      ["∞", "Total Views"],
    ],
  }),
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
};
</script>
