<template>
  <div id="app">
    <v-app>
      <!--      <navbar v-if="this.$router.currentRoute.path !== '/profile'"></navbar>-->
      <nav-drawer
        v-if="this.$router.currentRoute.path === '/profile'"
        clipped-left
      ></nav-drawer>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Navbar from "@/components/Navbar";
import NavDrawer from "@/components/NavDrawer";
export default {
  name: "app",
  components: {
    // Navbar,
    NavDrawer,
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
};
</script>
