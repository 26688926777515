import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import auth from "./auth";
import password from "./password";
import signup from "./signup";
import base from "./base";

const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    alerts: [],
  },
  getters: {
    alerts: (state) => state.alerts,
  },
  mutations: {
    setAlerts(state, payload) {
      state.alerts = [...payload];
    },
    pushAlerts(state, payload) {
      state.alerts.push(payload);
    },
  },
  actions: {
    setAlerts({ commit }, payload) {
      commit("setAlerts", payload);
    },
  },
  modules: {
    auth,
    password,
    signup,
    base,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
