<template>
  <v-container>
    <v-row>
      <v-col v-bind:key="index" v-for="(card, index) in cards">
        <v-card
          elevation="6"
          outlined
          v-on:click="toggleCard(card)"
          height="400px"
          min-width="200px"
          class="mx-auto my-12 text-center rounded-xl"
        >
          <v-card-title v-bind:key="card.flipped">
            {{ card.title }}
            <v-spacer></v-spacer>
            <span v-on:click="cards.splice(index, 1)" class="delete-card">
              X
            </span>
          </v-card-title>
          <v-card-text
            v-bind:key="card.flipped"
            class="fill-height align-center"
            justify="center"
          >
            <v-row justify="center" v-if="!card.flipped">
              <v-col cols="auto">
                <v-img
                  :src="getImage(card.front.icon)"
                  height="200px"
                  width="200px"
                ></v-img>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="auto">
                {{
                  card.flipped ? card.back.description : card.front.description
                }}
              </v-col>
            </v-row>
            <v-row justify="center" v-if="card.flipped && card.back.link">
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  elevation="3"
                  rounded
                  x-large
                  :href="card.back.link"
                  >See</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CardsFlipping",

  data() {
    return {
      cards: [
        {
          title: "Alexis",
          front: {
            description: "Portfolio",
            icon: "logo.svg",
          },
          back: {
            description: "Its my django portfolio",
            link: "alexis_main:index",
          },
          flipped: false,
        },
        {
          title: "Smerch",
          front: {
            description: "Portfolio",
            icon: "logo.svg",
          },
          back: {
            description: "i'm just create this main page",
            link: null,
          },
          flipped: false,
        },
      ],
    };
  },
  methods: {
    toggleCard: function (card) {
      card.flipped = !card.flipped;
    },
    getImage(img) {
      return require("@/assets/" + img);
    },
    setUrlByNames() {
      let urls = this.$store.getters["base/urls"];
      this.cards.forEach((card) => {
        urls.forEach((url) => {
          if (url.name === card.back.link) {
            card.back.link = url.url;
          }
        });
      });
    },
  },
  created() {
    this.$store.dispatch("base/getAllUrls");
  },
  watch: {
    "$store.state.base.urls": {
      handler() {
        this.setUrlByNames();
      },
    },
  },
};
</script>
