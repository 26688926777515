<template>
  <v-container fill-height class="landing justify-center text-center">
    <countdown
      v-if="counting"
      :time="time"
      :interval="100"
      @end="onCountdownEnd"
      v-slot="{ days, hours, minutes, seconds }"
    >
      <h1>
        {{ days }} days, {{ hours }} hours, {{ minutes }} minutes,
        {{ seconds }} seconds.
      </h1>
    </countdown>
    <transition name="slide-fade" v-if="!counting">
      <h1 class="landing_text text-center" v-if="show">{{ message }}</h1>
    </transition>
  </v-container>
</template>
<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import Vue from "vue";

Vue.component(VueCountdown.name, VueCountdown);

export default {
  name: "AngelLanding",
  data() {
    let landingMessages = [
      "Это больше, чем просто веб-сайт",
      "Это о тебе и твоем 20 летии...",
      "О тебе и твоих достижениях",
      "Ты готова?",
      "Добро пожаловать на сайт, это мой подарок тебе на 20 лет",
    ];
    const now = new Date();
    const birthday = new Date(2022, 11, 25);

    return {
      message: "",
      messages: landingMessages,
      show: true,
      showButton: false,
      count: 0,
      maxCount: landingMessages.length - 1,
      timeNow: now,
      birthday: birthday,
      time: birthday - now,
      counting: true,
    };
  },
  methods: {
    fadeText() {
      this.show = false;
      this.count++;
      this.message = this.messages[this.count];
      setTimeout(this.showText, 1250);
    },
    showText() {
      this.show = true;
      if (this.count !== this.maxCount) {
        setTimeout(this.fadeText, 2250);
      } else {
        this.showButton = true;
        setTimeout(() => this.$router.push("/angel/home"), 4000);
      }
    },
    onCountdownEnd() {
      this.counting = false;
      this.message = this.messages[this.count];
      setTimeout(this.fadeText, 2000);
    },
  },
};
</script>

<style scoped>
.landing {
  font-size: 3vw;
}

.landing_text {
  vertical-align: baseline;
}
</style>
