import auth from "../api/auth";
import session from "../api/session";
import {
  ACCOUNT_DETAILS_FAILURE,
  ACCOUNT_DETAILS_SUCCESS,
  LOGIN_BEGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  REMOVE_TOKEN,
  SET_TOKEN,
} from "./types";

const initialState = {
  authenticating: false,
  error: false,
  token: null,
  accountDetails: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  accountDetails: (state) => state.accountDetails,
};

const actions = {
  login({ commit }, { username, password }) {
    commit(LOGIN_BEGIN);
    return auth
      .login(username, password)
      .then(({ data }) => commit(SET_TOKEN, data.key))
      .then(() => commit(LOGIN_SUCCESS))
      .catch(() => commit(LOGIN_FAILURE));
  },
  getAccountDetails({ commit }) {
    return auth
      .getAccountDetails()
      .then(({ data }) => {
        commit(ACCOUNT_DETAILS_SUCCESS, data);
      })
      .catch(() => {
        commit(ACCOUNT_DETAILS_FAILURE);
      });
  },
  logout({ commit }) {
    return auth
      .logout()
      .then(() => commit(LOGOUT))
      .finally(() => commit(REMOVE_TOKEN));
  },
  initialize({ commit }) {
    commit(REMOVE_TOKEN);
  },
};

const mutations = {
  [LOGIN_BEGIN](state) {
    state.authenticating = true;
    state.error = false;
  },
  [LOGIN_FAILURE](state) {
    state.authenticating = false;
    state.error = true;
  },
  [LOGIN_SUCCESS](state) {
    state.authenticating = false;
    state.error = false;
  },
  [LOGOUT](state) {
    state.authenticating = false;
    state.error = false;
    state.accountDetails = null;
  },
  [SET_TOKEN](state, token) {
    session.defaults.headers.Authorization = `Token ${token}`;
    state.token = token;
  },
  [REMOVE_TOKEN](state) {
    delete session.defaults.headers.Authorization;
    state.token = null;
  },
  [ACCOUNT_DETAILS_SUCCESS](state, data) {
    state.accountDetails = data;
  },
  [ACCOUNT_DETAILS_FAILURE](state) {
    state.accountDetails = null;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
