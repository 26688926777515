<template>
  <v-main fill-height class="home home_container">
    <v-container fill-height class="home_content">
      <!-- Birthday cake -->
      <v-row class="home_row cake_row" style="position: relative">
        <v-col>
          <transition name="slide-fade">
            <div class="cake" v-if="showCake">
              <div class="plate"></div>
              <div class="layer layer-bottom"></div>
              <div class="layer layer-middle"></div>
              <div class="layer layer-top"></div>
              <div class="icing"></div>
              <div class="drip drip1"></div>
              <div class="drip drip2"></div>
              <div class="drip drip3"></div>
              <div class="candle">
                <div class="flame"></div>
              </div>
            </div>
          </transition>
        </v-col>
      </v-row>

      <!-- Buttons -->
      <v-row fill-height class="text-center pt-8 justify-center">
        <v-col style="max-width: 275px">
          <transition name="slide-fade">
            <v-btn
              @click="goMessages()"
              elevation="4"
              large
              rounded
              x-large
              block
              outlined
              v-if="showButtons"
              color="pink"
            >
              <v-icon>mdi-ice-pop</v-icon>
              Истории
            </v-btn>
          </transition>
        </v-col>
      </v-row>
      <v-row fill-height class="text-center justify-center">
        <v-col style="max-width: 275px">
          <transition name="slide-fade">
            <v-btn
              @click="goVideo()"
              elevation="4"
              large
              rounded
              x-large
              block
              outlined
              v-if="showButtons"
              color="pink"
            >
              <v-icon>mdi-cupcake</v-icon>
              Видео
            </v-btn>
          </transition>
        </v-col>
      </v-row>
      <v-row fill-height class="text-center justify-center">
        <v-col style="max-width: 275px">
          <transition name="slide-fade">
            <v-btn
              @click="Music()"
              elevation="4"
              large
              rounded
              x-large
              block
              outlined
              v-if="showButtons"
              color="pink"
            >
              <v-icon>mdi-music</v-icon>
              {{ musicText }}
            </v-btn>
          </transition>
        </v-col>
      </v-row>
    </v-container>

    <!-- For background effect -->
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </v-main>
</template>

<script>
import song from "@/assets/angel/happy-birthday-to-you-piano-version-13976.mp3";

export default {
  name: "AngelHome",
  data() {
    return {
      showCake: false,
      showButtons: false,
      musicText: "OFF музыку",
      music: false,
      song: new Audio(song),
    };
  },
  mounted: function () {
    this.showCake = true;
    this.showButtons = true;
    this.music = true;
    this.song.pause();
    this.song.play();
  },
  methods: {
    goMessages() {
      this.$router.push("/angel/messages");
    },
    goVideo() {
      this.$router.push("/angel/video");
    },
    Music() {
      if (this.music) {
        this.music = false;
        this.musicText = "ON музыку";
        this.song.pause();
      } else {
        this.music = true;
        this.musicText = "OFF музыку";
        this.song.play();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.song.pause();
    next();
  },
};
</script>

<style scoped lang="scss">
@import "../styles/Home.scss";
@import "../styles/BirthdayCake.scss";

.home {
  position: relative !important;
  overflow: hidden !important;
}

.home_container {
  height: 100vh !important;
  background: #fde2e2 !important;
  position: relative !important;
}

.home_content {
  z-index: 1 !important;
  display: block !important;
  position: relative !important;
}

.cake_row {
  height: 35vh !important;
}
</style>
