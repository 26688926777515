import { render, staticRenderFns } from "./AngelHome.vue?vue&type=template&id=06e27c03&scoped=true&"
import script from "./AngelHome.vue?vue&type=script&lang=js&"
export * from "./AngelHome.vue?vue&type=script&lang=js&"
import style0 from "./AngelHome.vue?vue&type=style&index=0&id=06e27c03&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e27c03",
  null
  
)

export default component.exports