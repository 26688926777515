import session from "./session";
import store from "../store";

export default {
  login(username, password) {
    return session.post("/auth/login/", { username, password }).catch((res) => {
      for (let key in res.response.data) {
        for (let message_text of res.response.data[key]) {
          store.commit("pushAlerts", {
            type: "error",
            message: message_text,
          });
        }
      }
    });
  },
  logout() {
    return session.post("/auth/logout/", {});
  },
  createAccount(username, password1, password2, email) {
    return session
      .post("/registration/", {
        username,
        password1,
        password2,
        email,
      })
      .catch((res) => {
        for (let key in res.response.data) {
          for (let message_text of res.response.data[key]) {
            store.commit("pushAlerts", {
              type: "error",
              message: message_text,
            });
          }
        }
      })
      .then(() => {
        store.commit("pushAlerts", {
          type: "success",
          message: "Account created successfully!",
        });
      });
  },
  changeAccountPassword(password1, password2) {
    return session.post("/auth/password/change/", { password1, password2 });
  },
  sendAccountPasswordResetEmail(email) {
    return session.post("/auth/password/reset/", { email });
  },
  resetAccountPassword(uid, token, new_password1, new_password2) {
    // eslint-disable-line camelcase
    return session.post("/auth/password/reset/confirm/", {
      uid,
      token,
      new_password1,
      new_password2,
    });
  },
  getAccountDetails(data) {
    return session.get("/auth/user/", data);
  },
  updateAccountDetails(data) {
    return session.patch("/auth/user/", data);
  },
  verifyAccountEmail(key) {
    return session.post("/registration/verify-email/", { key });
  },
};
